import { authAxios } from '../../../helpers/axios'
import { backendUrl } from '../../../env-config'
import { any } from 'zod'

export const employeeBulkBudgetAllocation = async (employees: any, values: any) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/allocate-bulk-employee-budget`, {
        employees,
        amount: values.amount,
        remark: values.remark,
        type: values.type,
        budgetType: values.budgetType,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const removeEmployeeFromCompany = async (employeeId: number) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/remove`, {
        employeeId,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const editEmployeeRoles = async (employeeId: number, roles: string[]) => {
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/employee/edit`, {
        employeeId,
        roles
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const employeeTagManagement = async(employeeId: string[], tagId: string[], action: string) =>{
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/tag/employee-tag-management`, {
        employeeId: employeeId, tagId: tagId, action,
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const getAllTags = async() =>{
  return new Promise<ApiResponseI>(async (resolve, reject) => {
    try {
      const res = await authAxios.get(`${backendUrl}/hr/tag/get-all-tags`, {
      })
      resolve(res.data)
    } catch (err: any) {
      reject(err.response?.data.message || err.message)
    }
  })
}

export const createTag = async (name: string, description: string) => {
  return new Promise<ApiResponseI>(async (resolve, rejects) => {
    try {
      const res = await authAxios.post(`${backendUrl}/hr/tag/create-tag`, {
        name: name,
        description: description,
      })
      resolve(res.data)
    } catch (err: any) {
      rejects(err.response?.data.message || err.message)
    }
  })
}

