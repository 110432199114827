import React, { useState, useEffect } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import css from './EmployeeSlectedByTagPopup.module.scss'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Button, CircularProgress, Radio, RadioGroup, FormControlLabel } from '@material-ui/core'
import ReactSelect from 'react-select'
import * as yup from 'yup'
import SmallRoundCloseButton from '../SmallRoundCloseButton/SmallRoundCloseButton'
import { useAlert } from 'react-alert'
import { getAllTags } from '../../containers/HR/HREmployeesPage/HREmployeesPage.helper'
import {
  addOrRemoveEmployeeFromProgramBulk,
  fetchTagEmployeeCount,
  fetchTagEmployeeIds,
} from '../../containers/HR/HRCurriculumEditEmployees/HRCurriculumEditEmployees.helper'
import { useConfirm } from 'material-ui-confirm'

interface FormFieldsI {
  tags: { value: string; label: string }[]
  selectionType: string  
}

interface PropsI {
  curriculumId: string
  trigger: JSX.Element | ((isOpen: boolean) => JSX.Element)
  onSuccessCallback: () => void
}

const EmployeeSelectionPopup: React.FC<PropsI> = (props) => {
  const { trigger, onSuccessCallback } = props
  const [tags, setTags] = useState<{ value: string; label: string }[]>([])
  const [loading, setLoading] = useState(false)
  const [userCounts, setUserCounts] = useState<{ tag: string; count: number }[]>([])
  const [selectionType, setSelectionType] = useState<string>('any')
  const alert = useAlert()
  const confirm = useConfirm()

  useEffect(() => {
    const fetchTags = async () => {
      setLoading(true)
      try {
        const data = await getAllTags()
        if (data?.success && Array.isArray(data?.data)) {
          setTags(data.data.map((tag: any) => ({ value: tag.id, label: tag.name })))
        } else {
          alert.error('Failed to fetch tags: No tags found')
        }
      } catch (err) {
        console.error('Error:', err)
      } finally {
        setLoading(false)
      }
    }

    fetchTags()
  }, [selectionType])

  const validationSchema = yup.object({
    tags: yup.array().min(1, 'At least one tag must be selected').required(),
  })

  const fetchEmployeeCount = async (
    selectedTags: { value: string; label: string }[],
    selection: string
  ) => {
    const tagIds = selectedTags.map((tag) => tag.value)
    if (!tagIds || tagIds.length > 0) {
      await fetchTagEmployeeCount(tagIds, selection)
        .then(async (res) => {
          let counts: { key: string; tag: string; count: number }[] = []
          if (selection === 'all') {
            counts.push({
              key: 'total-emp-count',
              tag: 'Total Employee Count',
              count: res.data.length,
            })
          } else {
            counts = res.data.map((item: any, index: number) => ({
              key: item.name,
              tag: item.name,
              count: item.userCount || 0,
            }))
          }
          setUserCounts(counts)
        })
        .catch((err) => console.log(err))
    } else {
      setUserCounts([])
    }
  }

  const initialValues: FormFieldsI = { tags: [], selectionType: 'any' }

  return (
    <Popup
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      onClose={() => {
        setUserCounts([])
      }}
    >
      {(close: any) => (
        <div className={css.base}>
          <SmallRoundCloseButton onClick={close} className={css.close_button} />
          <div className={css.header}>
            <p className={css.title}>Select Employee by Tags</p>
          </div>

          <Formik
            initialValues={initialValues}
            onSubmit={async (values, formikHelpers) => {
              confirm()
                .then(async () => {
                  const selectedTags = values.tags || []
                  await fetchTagEmployeeIds(
                    selectedTags.map((tag) => tag.value),
                    selectionType
                  )
                    .then(async (res) => {                      
                      const userIds = res.data?.map((user: { userId: string }) => user.userId)
                      await addOrRemoveEmployeeFromProgramBulk(props.curriculumId, userIds, true)
                      onSuccessCallback()
                      close()
                    })
                    .catch((error) => {
                      alert.error(error)
                    })
                })
                .catch((error) => {
                  alert.error(error)
                  close()
                })
                .finally(() => {
                  setUserCounts([])
                  formikHelpers.setSubmitting(false)
                })
            }}
            validationSchema={validationSchema}
          >
            {(formikProps: FormikProps<FormFieldsI>) => (
              <Form className={css.form}>
                <Field name="tags">
                  {({ field, form }: FieldProps) => {
                    const handleSelectionChange = (selected: any) => {
                      form.setFieldValue(
                        field.name,
                        selected
                          ? selected.map((tag: any) => ({ value: tag.value, label: tag.label }))
                          : []
                      )
                      fetchEmployeeCount(selected, selectionType)
                    }

                    return (
                      <ReactSelect
                        isMulti
                        options={tags}
                        value={field.value}
                        onChange={handleSelectionChange}
                        placeholder="Select tags..."
                        isLoading={loading}
                        getOptionLabel={(e) => `${e.label}`}
                        getOptionValue={(e) => `${e.value}`}
                      />
                    )
                  }}
                </Field>

                <Field name="selectionType">
                  {({ field, form }: FieldProps) => (
                    <RadioGroup
                      {...field}
                      row
                      value={selectionType}
                      onChange={(event) => {
                        field.onChange(event)
                        setSelectionType(event.target.value)
                        const selectedTags = form.values.tags || []
                        setUserCounts([])
                        fetchEmployeeCount(selectedTags, event.target.value)
                      }}
                    >
                      <FormControlLabel
                        className={css.mr}
                        value="any"
                        control={<Radio />}
                        label="Any Tag"
                      />
                      <FormControlLabel value="all" control={<Radio />} label="All Tags" />
                    </RadioGroup>
                  )}
                </Field>

                <br />
                {userCounts.length > 0 && (
                  <div className={css.tag_list}>
                    {userCounts.map((entry) => (
                      <span key={entry.tag} className={css.tag}>
                        {entry.tag}
                        <span className={css.count_circle}>{entry.count}</span>
                      </span>
                    ))}
                  </div>
                )}

                <Button
                  className={css.submit_button}
                  color="primary"
                  variant="contained"
                  type="submit"
                  disableElevation
                  disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                >
                  {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Select Employee'}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Popup>
  )
}

export default EmployeeSelectionPopup
