import React from "react";
import { useConfirm } from "material-ui-confirm";
import { useAlert } from 'react-alert'

export function HREmployeePageWithConfirm(Component: React.ComponentType<any>) {
  return function WrappedComponent(props: any) {
    const confirm = useConfirm();
    const alert = useAlert()
    return <Component {...props} confirm={confirm} alert={alert} />;
  };
}
