import { Button } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchCompanyPurchase } from '../HRBillingPage.helper'
import { Purchase } from './InvoiceType'
import moment from 'moment'
import css from './BillingCompanyInvoiceView.module.scss'

type Props = {}

export default function InvoiceReport({}: Props) {
  const { id } = useParams<{ id: string }>()
  const [purchase, setPurchase] = useState<Purchase | null>(null)
  console.log('🚀 ~ file: InvoiceReport.tsx:11 ~ InvoiceReport ~ purchase:', purchase)

  useEffect(() => {
    fetchCompanyPurchase(id).then((res) => {
      setPurchase(res.data)
    })
  }, [id])

  return (
    <div className="p-5 print:p-0">

      <div className="mt-5">

        {/* Print Button */}
        <div className="flex w-full print:hidden rounded-lg justify-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.print()}
          >
            Print Invoice
          </Button>
        </div>

        {/* Invoice Header */}
        <div className="mt-8 text-center">
          <h1 className="text-4xl font-bold text-gray-800 ml-5">Invoice Report</h1>
          <p className="text-gray-600 text-sm ml-5">
            Generated At: {moment().format("MMMM Do YYYY, h:mm:ss a")}
          </p>
        </div>

        {/* Invoice Details Table */}
        <div className="mt-6 p-5 rounded-lg ">
          <h2 className="text-xl font-semibold text-gray-700 border-b pb-2 mb-4">
            Invoice Details
          </h2>
          <table className={css.table_custom}>
            <colgroup>
              <col className="w-1/3" />
              <col className="w-2/3" />
            </colgroup>
            <thead>
              <tr className={css.table_row}>
                <th className={css.table_cell}>Field</th>
                <th className={css.table_cell}>Details</th>
              </tr>
            </thead>
            <tbody>
            <tr className={css.table_row_normal}>
              <td className={css.table_cell_amount}>
                Amount
              </td>
              <td className={css.table_cell_amount}>
                Rs {purchase?.amount}
              </td>
            </tr>
            <tr className={css.table_row_normal}>
              <td className={css.table_cell}>Payment Method</td>
              <td className={css.table_cell}>{purchase?.paymentMethod}</td>
            </tr>
            </tbody>
          </table>
        </div>

        {/* Curriculum Details Table */}
        <div className="mt-6 bg-white p-5 rounded-lg ">
          <h2 className="text-xl font-semibold text-gray-700 border-b pb-2 mb-4">
            Curriculum Details
          </h2>
          <table className={css.table_custom}>
            <colgroup>
              <col className="w-1/3" />
              <col className="w-2/3" />
            </colgroup>
            <thead>
            <tr className={css.table_row}>
              <th className={css.table_cell}>Field</th>
              <th className={css.table_cell}>Details</th>
            </tr>
            </thead>
            <tbody>
            <tr className={css.table_row_normal}>
              <td className={css.table_cell}>Name</td>
              <td className={css.table_cell}>
                {purchase?.enrolledCurriculum.curriculum.name}
              </td>
            </tr>
            <tr className={css.table_row_normal}>
              <td className={css.table_cell}>Type</td>
              <td className={css.table_cell}>
                {purchase?.enrolledCurriculum.curriculum.type}
              </td>
            </tr>
            <tr className={css.table_row_normal}>
              <td className={css.table_cell}>Enrolled At</td>
              <td className={css.table_cell}>
                {moment(purchase?.enrolledCurriculum.createdAt).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        {/* Coach Details Table */}
        <div className="mt-6 bg-white p-5 rounded-lg ">
          <h2 className="text-xl font-semibold text-gray-700 border-b pb-2 mb-4">
            Coach Details
          </h2>
          <table className={css.table_custom}>
            <colgroup>
              <col className="w-1/3" />
              <col className="w-2/3" />
            </colgroup>
            <thead>
            <tr className={css.table_row}>
             <th className={css.table_cell}>Field</th>
             <th className={css.table_cell}>Details</th>
            </tr>
            </thead>
            <tbody>
             <tr className={css.table_row_normal}>
              <td className={css.table_cell}>Name</td>
              <td className={css.table_cell}>
                {purchase?.enrolledCurriculum.coach.displayName}
              </td>
            </tr>
             <tr className={css.table_row_normal}>
              <td className={css.table_cell}>Email</td>
              <td className={css.table_cell}>
                {purchase?.enrolledCurriculum.coach.email}
              </td>
            </tr>
            {purchase?.enrolledCurriculum.coach.location && (
               <tr className={css.table_row_normal}>
                <td className={css.table_cell}>Location</td>
                <td className={css.table_cell}>
                  {purchase?.enrolledCurriculum.coach.location}
                </td>
              </tr>
            )}
            {purchase?.enrolledCurriculum.coach.phoneNumber && (
               <tr className={css.table_row_normal}>
                <td className={css.table_cell}>Phone Number</td>
                <td className={css.table_cell}>
                  {purchase?.enrolledCurriculum.coach.phoneNumber}
                </td>
              </tr>
            )}
            {purchase?.enrolledCurriculum.coach.isPremium && (
               <tr className={css.table_row_normal}>
                <td className={css.table_cell}>Premium Coach</td>
                <td className={css.table_cell}>
                  {purchase?.enrolledCurriculum.coach.isPremium ? "Yes" : "No"}
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </div>

        {/* Attendee Details Table */}
        <div className="mt-6 bg-white p-5 rounded-lg ">
          <h2 className="text-xl font-semibold text-gray-700 border-b pb-2 mb-4">
            Attendee Details
          </h2>
          <table className={css.table_custom}>
            <colgroup>
              <col className="w-1/3" />
              <col className="w-2/3" />
            </colgroup>
            <thead>
            <tr className={css.table_row}>
              <th className={css.table_cell}>Field</th>
              <th className={css.table_cell}>Details</th>
            </tr>
            </thead>
            <tbody>
             <tr className={css.table_row_normal}>
              <td className={css.table_cell}>Name</td>
              <td className={css.table_cell}>
                {purchase?.enrolledCurriculum.attendee.displayName}
              </td>
            </tr>
             <tr className={css.table_row_normal}>
              <td className={css.table_cell}>Email</td>
              <td className={css.table_cell}>
                {purchase?.enrolledCurriculum.attendee.email}
              </td>
            </tr>
            {purchase?.enrolledCurriculum.attendee.location && (
               <tr className={css.table_row_normal}>
                <td className={css.table_cell}>Location</td>
                <td className={css.table_cell}>
                  {purchase?.enrolledCurriculum.attendee.location}
                </td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
