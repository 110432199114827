import React, { useEffect, useState } from 'react'
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from '@material-ui/core'
import { Field, Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import { TextField } from 'formik-material-ui'
import css from './HRSettingPage.module.scss'
import { fetchCompanySettings, imageUpload, saveCompanySettings } from './HRSettingsPage.helper'
import { FormikHelpers } from 'formik/dist/types'
import NumberFormatInputField from '../../../components/NumberFormatInputField/NumberFormatInputField'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ThunkDispatch } from 'redux-thunk'
import { fetchCurrentUser } from '../../../ducks/user.duck'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

interface PropsI extends MapDispatchPropsI {}

interface FormPropsI {
  FYBudget: number | string
  FYUserBudget: number | string
  FYMonth?: number | string
  logoUrl?: string
}

const HRSettingPage: React.FC<PropsI> = ({ fetchCurrentUserA }) => {
  const [logo, setLogo] = useState(null)
  const [initialValues, setInitialValues] = useState<FormPropsI>({
    FYBudget: '',
    FYUserBudget: '',
    FYMonth: 'none',
    logoUrl: '',
  })

  useEffect(() => {
    fetchCompanySettings()
      .then((res) => {
        console.log(res?.data)

        if (res.data) {
          setInitialValues({
            FYBudget: res?.data?.FYBudget,
            FYUserBudget: res?.data?.FYUserBudget,
            FYMonth: res?.data?.FYMonth,
            logoUrl: res?.data?.logoUrl,
          })
          setLogo(res?.data?.logoUrl)
        } else {
          setInitialValues({
            FYBudget: 0,
            FYUserBudget: 0,
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const validationSchema = yup.object({
    FYBudget: yup.number().label('Financial year budget').required(),
    FYUserBudget: yup.number().label('Financial year default user budget').required(),
    FYMonth: yup.number().label('Financial year ending month').required(),
  })

  const onSubmitForm = (values: any, formikHelpers: FormikHelpers<FormPropsI>) => {
    saveCompanySettings(values)
      .then(() => {
        formikHelpers.setSubmitting(false)
        setInitialValues(values)
        fetchCurrentUserA()
      })
      .catch(() => {
        formikHelpers.setSubmitting(true)
      })
  }

  return (
    <MainDashboardWrapper title="Settings" page="corp-settings">
      <div className="w-full flex justify-end items-center h-20">
        <Link to="/hr/resources" className="w-fit">
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disableElevation
            className="w-fit"
          >
            Client resources
          </Button>
        </Link>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitForm}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {(formikProps: FormikProps<FormPropsI>) => {
          const uploadImage = (event: any) => {
            if (event.target.files && event.target.files[0]) {
              let img = event.target.files[0]
              imageUpload(img).then((res: any) => {
                const url = res.data.data.secure_url
                setLogo(url)
                formikProps.setFieldValue('logoUrl', url)
              })
            }
          }

          return (
            <Form className="flex flex-col bg-gray-100 p-5 rounded-lg">
              <div className="mt-5 flex flex-row mb-10">
                <div className="w-1/4">
                  <FormControl variant={'outlined'} className="w-fit">
                    <p className="text-gray-600 font-medium mb-5 text-lg">Company Logo</p>
                    <Button component="label" className="p-0">
                      <div className="m-0 p-0 w-40 h-40 rounded-lg bg-gray-200 flex items-center justify-center cursor-pointer">
                        {logo ? (
                          <img
                            src={logo}
                            alt="company logo"
                            className="h-40 w-40 rounded-lg object-cover"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faCloudUploadAlt}
                            className="text-[#007373] fa-5x"
                          />
                        )}
                        <input
                          hidden
                          accept="image/*"
                          multiple
                          type="file"
                          onChange={uploadImage}
                        />
                      </div>
                    </Button>
                  </FormControl>
                </div>

                <div className="w-3/4 flex justify-center items-center">
                  <FormControl variant={'outlined'} className="w-full">
                    <p className="text-gray-600 font-medium mb-5 text-lg">FY Ending Month</p>
                    <Select
                      value={formikProps.values.FYMonth}
                      onChange={(e) => formikProps.setFieldValue('FYMonth', e.target.value)}
                    >
                      <MenuItem disabled value={'none'}>Not Selected</MenuItem>
                      <MenuItem value={0}>January</MenuItem>
                      <MenuItem value={1}>February</MenuItem>
                      <MenuItem value={2}>March</MenuItem>
                      <MenuItem value={3}>April</MenuItem>
                      <MenuItem value={4}>May</MenuItem>
                      <MenuItem value={5}>June</MenuItem>
                      <MenuItem value={6}>July</MenuItem>
                      <MenuItem value={7}>August</MenuItem>
                      <MenuItem value={8}>September</MenuItem>
                      <MenuItem value={9}>October</MenuItem>
                      <MenuItem value={10}>November</MenuItem>
                      <MenuItem value={11}>December</MenuItem>
                    </Select>
                    <p className="text-[#007373] font-medium mt-5 ml-5 text-sm">The financial budget will be allocated automatically on the selected month above</p>
                  </FormControl>
                </div>
              </div>


              <p className="text-gray-600 font-medium text-lg">Budget for Financial Year</p>
              <Field
                className={css.input_field}
                component={TextField}
                name="FYBudget"
                variant="outlined"
                color="primary"
                required
                InputProps={{
                  inputComponent: NumberFormatInputField,
                }}
              />

              <p className="text-gray-600 font-medium mt-5 text-lg">Default employee budget per Financial Year</p>
              <Field
                className={css.input_field}
                component={TextField}
                name="FYUserBudget"
                variant="outlined"
                color="primary"
                required
                InputProps={{
                  inputComponent: NumberFormatInputField,
                }}
              />

              <div className="w-full flex justify-end items-center h-50">
                <Button
                  className={css.submit_button}
                  color="primary"
                  variant="contained"
                  type="submit"
                  disableElevation
                  // disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                >
                  {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Save'}
                </Button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </MainDashboardWrapper>
  )
}

interface MapDispatchPropsI {
  fetchCurrentUserA: () => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI) => {
  return {
    currentUser: state.user.currentUser,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>) => {
  return {
    fetchCurrentUserA: () => dispatch(fetchCurrentUser()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HRSettingPage))
