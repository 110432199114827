import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import css from './HRCurriculumEditListings.module.scss'
import { ThunkDispatch } from 'redux-thunk'
import { Column } from 'react-table'
import { Avatar } from '@material-ui/core'
import { useAlert } from 'react-alert'
import { useDebouncedCallback } from 'use-debounce'
import {
  addListingToCurriculum,
  fetchCoachesForCurriculum,
  fetchListingsFromMyCoaches,
  fetchListingsInACurriculum,
  removeListingFromCurriculum,
} from './HRCurriculumEditListings.helper'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { faArrowAltCircleLeft, faFolderOpen, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import ReactHtmlParser from 'react-html-parser'
import { decode } from 'html-entities'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import Stepper from '../../../components/Stepper/Stepper'
import { vivekaUrl } from '../../../env-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { listingCurriculum } from '../../../components/Breadcrumbs/BreadcrumbItems'

interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps<{ id: string }> {}

const HRCurriculumEditListings: React.FC<PropsI> = (props) => {
  const { match, history } = props
  const [searchText, setSearchText] = useState('')
  const [listings, setListings] = useState<any[]>([])
  const [sListings, setSListings] = useState<any[]>([])
  const [coaches, setCoaches] = useState<any[]>([])

  const loadPage = useCallback(() => {
    fetchListingsInACurriculum(match.params.id || '').then((res) => {
      setSListings(res.data)
    })
    fetchCoachesForCurriculum(match.params.id).then((res) => setCoaches(res.data))
  }, [match.params.id])

  useEffect(() => {
    loadPage()
  }, [loadPage])

  const alert = useAlert()

  const fetchListings = useDebouncedCallback(
    (value: string) => {
      fetchListingsFromMyCoaches(value, match.params.id).then((res) => {
        setListings(res.data)
      })
    },
    600,
    { maxWait: 2000 }
  )

  const searchTextOnChange = (value: string) => {
    setSearchText(value)
    fetchListings(value)
  }

  const addListingButtonHandle = (listingId: number) => {
    addListingToCurriculum(listingId, Number(match.params.id))
      .then((res) => {
        alert.success('Listing added to the curriculum')
        loadPage()
      })
      .catch((err) => {
        alert.error(err)
      })
  }

  const removeButtonHandle = (listingId: number) => {
    removeListingFromCurriculum(listingId, Number(match.params.id))
      .then((res) => {
        alert.success('Listing removed from the curriculum')
        loadPage()
      })
      .catch((err) => {
        alert.error(err)
      })
  }

  let coachListings: any[] = []

  coaches.forEach((x) => {
    x?.listings?.forEach((y: any) => {
      coachListings.push({
        coach: x,
        listing: y,
      })
    })
  })

  return (
    <MainDashboardWrapper breadcrumbItems={listingCurriculum} title="Update Listings" page="corp-curriculum">
      <Stepper
        steps={[
          {
            title: `Add Curriculum`,
            isComplete: true,
          },
          {
            title: 'Add Listings',
            isSelected: true,
          },
          {
            title: 'Add Employees',
          },
        ]}
      />

      <div className='flex flex-col'>
        <div
          onClick={() => history.push(`/hr/curriculum/edit/employees/${match.params.id}`)}
          className={css.continue_button}
        >
          Continue
        </div>
      </div>

      <div className='flex flex-col pb-5'>
        <p className="font-bold text-xl">
          Recommended coaches filtered by price range and categories, Please add listings and click continue
        </p>
      </div>

      <div className="grid grid-cols-2 w-full">
        <div className={css.left_container}>
          <div className={css.header_bg}>
            <p className="text-3xl">
              Available coaches
            </p>
          </div>
          <div className={css.listings}>
            {coachListings.length == 0 && (
              <div className="flex gap-5 items-center">
                <FontAwesomeIcon icon={faFolderOpen} size="3x" className="text-gray-400" />
                <p className="text-gray-400">
                  No listings matching for this curriculum. <br /> Add more Coaches to the company to
                  see more listings.
                </p>
              </div>
            )}
            {coachListings.map((item) => {
              const isSelected = sListings?.find((x) => x.id === item?.listing.id)
              if (isSelected) return null
              return (
                <div key={item?.listing.id} className={css.container}>
                  <img
                    className={css.listing_image}
                    alt="listing"
                    src={item?.listing.images[0] || ''}
                  />
                  <div className={css.listing_details}>
                    <a>{item?.listing.name}</a>
                    <a
                      href={`${vivekaUrl}/u/${item?.coach.uuid}`}
                      target="_blank"
                      className={css.coach_name}
                    >
                      {item?.coach.displayName}
                    </a>
                    <div className={css.listing_description}>
                      {ReactHtmlParser(decode(item?.listing.description))}
                    </div>
                    <div className={css.listing_categories}>
                      {item?.listing?.typeOfService?.map((t: any, i: number) => {
                        return <div key={i}>{t}</div>
                      })}
                    </div>
                  </div>
                  <div className={css.listing_action}>
                    <p>
                      ${item?.listing.price.toLocaleString()} / {item?.listing.type}
                    </p>

                    {!sListings?.find((x) => x.id === item?.listing.id) && (
                      <div
                        className={css.button3}
                        onClick={() => addListingButtonHandle(item?.listing.id)}
                      >
                        <p className='pr-2'>Add</p>
                        <FontAwesomeIcon icon={faArrowAltCircleRight} size="2x" className="text-white" />
                      </div>
                    )}

                    <a
                      className={css.button2}
                      href={`${vivekaUrl}/l/${item?.listing.uuid}`}
                      target="_blank"
                    >
                      View
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="p-[30px]">
          <div className={css.header_bg}>
            <p className="text-3xl">
              Added coaches
            </p>
          </div>
          <div className={css.listings}>
            {sListings.map((item) => {
              return (
                <div key={item?.id} className={css.container}>
                  <img className={css.listing_image} alt="listing" src={item?.images[0] || ''} />
                  <div className={css.listing_details}>
                    <a>{item?.name}</a>
                    <a
                      href={`${vivekaUrl}/u/${item?.owner.uuid}`}
                      target="_blank"
                      className={css.coach_name}
                    >
                      {item?.owner.displayName}
                    </a>
                    <div className={css.listing_description}>
                      {ReactHtmlParser(decode(item?.description))}
                    </div>
                    <div className={css.listing_categories}>
                      {item?.typeOfService?.map((t: any, i: number) => {
                        return <div key={i}>{t}</div>
                      })}
                    </div>
                  </div>
                  <div className={css.listing_action}>
                    <p>
                      ${item?.price.toLocaleString()} / {item?.type}
                    </p>

                    <div className={css.button3} onClick={() => removeButtonHandle(item?.id)}>
                      <p className='pr-2'>Remove</p>
                      <FontAwesomeIcon icon={faArrowAltCircleLeft} size="2x" className="text-white" />
                    </div>

                    <a className={css.button2} href={`${vivekaUrl}/l/${item?.uuid}`} target="_blank">
                      View
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </MainDashboardWrapper>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HRCurriculumEditListings))
