import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import css from './HRCoachesPage.module.scss'
import { ThunkDispatch } from 'redux-thunk'
import {
  fetchCoachesInMyCompany,
  removeCoachFromCompany,
  toUrlFormat,
} from './HRCoachesPage.helper'
import { Avatar, CircularProgress } from '@material-ui/core'
import { useConfirm } from 'material-ui-confirm'
import { useAlert } from 'react-alert'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import { Search } from 'react-feather'
import ReactHtmlParser from 'react-html-parser'
import { decode } from 'html-entities'
import BaseTable from '../../../components/BaseTable/BaseTable'
import TableActions from '../../../components/TableActions/TableActions'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { vivekaUrl } from '../../../env-config'

interface PropsI extends MapStatePropsI, MapDispatchPropsI {}

const HRCoachesPage: React.FC<PropsI> = () => {
  const [coaches, setCoaches] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [removeLoading, setRemoveLoading] = useState<number | null>(null)
  const confirm = useConfirm()
  const alert = useAlert()

  const pageLoad = useCallback(() => {
    setLoading(true)
    fetchCoachesInMyCompany()
      .then((res) => {
        setLoading(false)
        setCoaches(res.data)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  const removeCoach = (id: number) => {
    confirm().then(() => {
      setRemoveLoading(id)
      removeCoachFromCompany(id)
        .then((res) => {
          setRemoveLoading(null)
          alert.success(res.message)

          pageLoad()
        })
        .catch((err) => {
          setRemoveLoading(null)
          alert.error(err)
        })
    })
  }

  const viewCoach = (name: string, uuid: string) => {
    window.open(`${vivekaUrl}/u/${toUrlFormat(name)}/${uuid}`)
    return true
  }

  const columns = [
    {
      Header: 'Coaches',
      accessor: 'firstName',
      Cell: (props: any) => {
        if (!props.value) return ''

        const profilePic = props?.row?.original?.profilePic || ''

        return (
          <span
            className={css.employee_cell}
            onClick={() => viewCoach(props?.row?.original?.displayName, props?.row?.original?.uuid)}
          >
            <Avatar src={profilePic}>{props?.value?.charAt(0)}</Avatar>
            <span>{props?.value}</span>
          </span>
        )
      },
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Description',
      accessor: 'bio',
      Cell: (tableProps: any) =>
        ReactHtmlParser(decode(tableProps?.row?.original?.bio?.substring(0, 200))),
    },
    {
      Header: 'Remove',
      accessor: 'id',
      Cell: (tableProps: any) => (
        <TableActions
          actions={[
            {
              icon: faTrash,
              action: () => removeCoach(tableProps?.row?.original?.id),
              tooltip: 'View',
            },
          ]}
        />
      ),
      disableSortBy: true,
    },
  ]
  return (
    <MainDashboardWrapper title="Coaches" page="corp-coach">
      <div className={css.base}>
        <div className={css.actions}>
          <PrimaryButton
            to="/hr/coach/add"
            name="Find More"
            icon={Search}
            style={{ marginLeft: 10 }}
          />
        </div>

        {!loading && coaches.length < 1 && (
          <p>No coaches are added, Click "Find More" button to add coaches</p>
        )}
        {loading && <CircularProgress />}

        <BaseTable columns={columns} data={coaches} loading={loading} isSelectable />
      </div>
    </MainDashboardWrapper>
  )
}

interface MapStatePropsI {}

interface MapDispatchPropsI {}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {}
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>): MapDispatchPropsI => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HRCoachesPage)
