import React from 'react'
import css from './EditEmployeePopup.module.scss'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import SmallRoundColoseButton from '../SmallRoundCloseButton/SmallRoundCloseButton'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { Autocomplete } from '@material-ui/lab'
import * as yup from 'yup'
import { FormikHelpers } from 'formik/dist/types'
import {
  Button,
  CircularProgress,
  TextField as MuiTextField,
} from '@material-ui/core'
import { useAlert } from 'react-alert'
import { useConfirm } from 'material-ui-confirm'
import { editEmployeeRoles } from '../../containers/HR/HREmployeesPage/HREmployeesPage.helper'

interface PropsI extends RouteComponentProps {
  isOpen: boolean
  handleEditEmployeeModal: (isEdited: boolean) => void
  selectedEmployee: any
}

interface FormFieldsI {
  roles: string[]
}

const EditEmployeePopup: React.FC<PropsI> = (props) => {
  const { isOpen, handleEditEmployeeModal } = props

  const alert = useAlert()
  const confirm = useConfirm()

  const validationSchema = yup.object({
    roles: yup
      .array()
      .of(yup.string().required("Role is required"))
      .min(1, "At least one role is required")
      .label("Roles"),
  });

  const initialValues: FormFieldsI = { roles: props.selectedEmployee?.UserCompanyModel?.roles || [] }

  const onSubmit = (
    values: FormFieldsI,
    formikHelpers: FormikHelpers<FormFieldsI>,
    close: () => void
  ) => {
    confirm()
      .then(() => {
        editEmployeeRoles(props.selectedEmployee?.id, values?.roles)
          .then(() => {
            alert.success('Successfully edited')
            formikHelpers.setSubmitting(false)
            handleEditEmployeeModal(true)
          })
          .catch((err) => {
            alert.error(err)
            formikHelpers.setSubmitting(false)
            handleEditEmployeeModal(false)
          })
      })
      .catch(() => {
        formikHelpers.setSubmitting(false)
      })
  }

  let roleOptions = ['hr', 'employee', 'admin']

  return (
    <Popup open={isOpen} modal closeOnDocumentClick={false}>
      {(close: any) => (
        <div className={css.base}>
          <SmallRoundColoseButton onClick={()=>handleEditEmployeeModal(false)} className={css.close_button} />
          <p className={css.title}>Edit Employee Role</p>

          <Formik
            initialValues={initialValues}
            onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers, close)}
            validationSchema={validationSchema}
          >
            {(formikProps: FormikProps<FormFieldsI>) => {
              return (
                <Form className={css.form}>
                  <Field name="roles">
                    {(fieldProps: FieldProps) => {
                      const { field, meta } = fieldProps
                      return (
                        <Autocomplete
                          id="roles"
                          onBlur={field.onBlur}
                          multiple
                          options={roleOptions}
                          value={formikProps.values.roles}  // ✅ Ensures initial value is set
                          onChange={(event, value) => {
                            formikProps.setFieldValue('roles', value) // ✅ Updates Formik state
                          }}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <MuiTextField
                              {...params}
                              className={css.input_field}
                              variant="outlined"
                              label="Roles *"
                              error={meta.touched && !!meta.error}
                              helperText={meta.touched && meta.error}
                            />
                          )}
                        />
                      )
                    }}
                  </Field>

                  <Button
                    className={css.submit_button}
                    color="primary"
                    variant="contained"
                    type="submit"
                    disableElevation
                    onClick={() => formikProps.handleSubmit()}
                  >
                    {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Submit'}
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </div>
      )}
    </Popup>
  )
}

export default withRouter(EditEmployeePopup)
