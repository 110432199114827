import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import Stepper from '../../../components/Stepper/Stepper'
import css from './HRCurriculumEditEmployees.module.scss'
import { Avatar, Button } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import { decode } from 'html-entities'
import {
  addOrRemoveEmployeeFromProgram,
  addOrRemoveEmployeeFromProgramBulk,
  fetchCompanyEmployees,
  fetchCurriculumEmployees,
} from './HRCurriculumEditEmployees.helper'
import { useAlert } from 'react-alert'
import { useConfirm } from 'material-ui-confirm'
import BaseTable from '../../../components/BaseTable/BaseTable'
import TableActions from '../../../components/TableActions/TableActions'
import { faEye, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons'
import { listingCurriculum } from '../../../components/Breadcrumbs/BreadcrumbItems'
import EmployeeSlectedByTagPopup from '../../../components/EmployeeSlectedByTagPopup/EmployeeSlectedByTagPopup'

interface PropsI extends RouteComponentProps<{ id: string }> {}

const HRCurriculumEditEmployees: React.FC<PropsI> = (props) => {
  const { match, history } = props
  const [compEmployees, setCompEmployees] = useState<any[]>([])
  const [programEmployees, setProgramEmployees] = useState<any[]>([])

  const alert = useAlert()
  const confirm = useConfirm()

  const fetchAddedEmployees = () => {
    fetchCurriculumEmployees(match.params.id)
      .then((res) => {
        // console.log(res)
        setProgramEmployees(res.data)
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    fetchCompanyEmployees()
      .then((res) => {
        setCompEmployees(res.data)
      })
      .catch((err) => console.log(err))

    fetchAddedEmployees()
  }, [match.params.id])

  const addOrRemoveEmployee = (employeeId: string, isAdd: boolean) => {
    confirm().then(() => {
      addOrRemoveEmployeeFromProgram(match.params.id, employeeId, isAdd)
        .then((res) => {
          alert.success(res.message)
          fetchAddedEmployees()
        })
        .catch((err) => {
          alert.error(err)
        })
    })
  }
  const addOrRemoveEmployeeBulk = (isAdd: boolean) => {
    const selectedEmployeeIds = (window as any)?.selectedRows?.map((i: any) => i.id)
    console.log({ selectedEmployeeIds })
    confirm().then(() => {
      addOrRemoveEmployeeFromProgramBulk(match.params.id, selectedEmployeeIds, isAdd)
        .then((res) => {
          alert.success(res.message)
          fetchAddedEmployees()
        })
        .catch((err) => {
          alert.error(err)
        })
    })
  }

  const columns: any[] = [
    {
      Header: 'Is Selected',
      accessor: '',
      Cell: (tableProps: any) => {
        const isAdded = !!programEmployees.find((x: any) => x.id === tableProps?.row?.original?.id)
        let styles: any = { fontWeight: '700' }
        styles = { ...styles, color: isAdded ? 'green' : '' }
        return <div style={styles}>{isAdded ? 'Yes' : 'No'}</div>
      },
    },
    {
      Header: 'Name',
      accessor: 'displayName',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Bio',
      accessor: 'bio',
      Cell: (tableProps: any) => {
        return ReactHtmlParser(decode(tableProps?.value?.substring(0, 200)))
      },
    },
    {
      Header: 'Action',
      accessor: 'id',
      Cell: (tableProps: any) => {
        const isAdded = !!programEmployees.find((x: any) => x.id === tableProps.value)
        let actions = []
        actions.push({
          icon: faEye,
          tooltip: 'View Employee',
          action: () =>
            history.push(`/hr/curriculum/details/${match.params.id}/${tableProps.value}/budget`),
        })

        if (isAdded) {
          actions.push({
            icon: faTrash,
            tooltip: 'Remove Employee',
            action: () => addOrRemoveEmployee(tableProps.value, false),
          })
        } else {
          actions.push({
            icon: faPlus,
            tooltip: 'Add Employee',
            action: () => addOrRemoveEmployee(tableProps.value, true),
          })
        }

        return <TableActions actions={actions} />
      },
    },
  ]

  return (
    <MainDashboardWrapper
      breadcrumbItems={listingCurriculum}
      title="Update Listings"
      page="corp-curriculum"
    >
      <Stepper
        steps={[
          {
            title: `Add Curriculum`,
            isComplete: true,
          },
          {
            title: 'Add Listings',
            isComplete: true,
          },
          {
            title: 'Add Employees',
            isSelected: true,
          },
        ]}
      />

      {/*<div className={css.employees}>*/}
      {/*  {compEmployees.map((item) => {*/}
      {/*    const isAdded = !!programEmployees.find((x: any) => x.id === item.id)*/}
      {/*    return (*/}
      {/*      <div key={item.id} className={css.user_card_base}>*/}
      {/*        <div className={css.user_name_base}>*/}
      {/*          <Avatar src={item.profilePic} />*/}
      {/*          <div className={css.user_name}>*/}
      {/*            <p>{item.displayName}</p>*/}
      {/*            <p>{item.location}</p>*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div className={css.user_description}>*/}
      {/*          {ReactHtmlParser(decode(item.bio?.substring(0, 200)))}*/}
      {/*        </div>*/}

      {/*        <div className={css.user_actions}>*/}
      {/*          {isAdded ? (*/}
      {/*            <>*/}
      {/*              <p*/}
      {/*                className={css.user_add_btn}*/}
      {/*                onClick={() =>*/}
      {/*                  history.push(`/hr/curriculum/details/${match.params.id}/${item.id}/budget`)*/}
      {/*                }*/}
      {/*              >*/}
      {/*                Allocate Budget*/}
      {/*              </p>*/}
      {/*              <p*/}
      {/*                className={css.user_remove_btn}*/}
      {/*                onClick={() => addOrRemoveEmployee(item.id, false)}*/}
      {/*              >*/}
      {/*                Remove*/}
      {/*              </p>*/}
      {/*            </>*/}
      {/*          ) : (*/}
      {/*            <p*/}
      {/*              className={css.user_add_btn}*/}
      {/*              onClick={() => addOrRemoveEmployee(item.id, true)}*/}
      {/*            >*/}
      {/*              Add*/}
      {/*            </p>*/}
      {/*          )}*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</div>*/}

      <div className="flex items-center gap-5">
        <Button variant={'outlined'} onClick={() => addOrRemoveEmployeeBulk(true)}>
          Bulk Select
        </Button>
        <Button variant={'outlined'} onClick={() => addOrRemoveEmployeeBulk(false)}>
          Bulk remove
        </Button>
        <EmployeeSlectedByTagPopup
          trigger={<Button variant={'outlined'}>Select by Tag</Button>}
          onSuccessCallback={fetchAddedEmployees}
          curriculumId= {match.params.id}
        />
      </div>

      <BaseTable columns={columns} data={compEmployees} isSelectable />

      <div onClick={() => history.push('/hr/curriculum')} className={css.button}>
        Continue
      </div>
    </MainDashboardWrapper>
  )
}

export default withRouter(HRCurriculumEditEmployees)
