import React from 'react'
import { Line, Bar } from 'react-chartjs-2'
import css from './colors.module.scss'
import { Button, FormControl, MenuItem, Select } from '@material-ui/core'

type Props = {}

export default function CoachingAnalytics({}: Props) {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  // Revenue Growth Data
  const revenueData = {
    labels: months,
    datasets: [
      {
        label: 'Coached Cohort Revenue',
        data: [
          100000,
          120000,
          150000,
          180000,
          200000,
          250000,
          280000,
          310000,
          350000,
          380000,
          420000,
          450000,
        ],
        borderColor: css.chartColor1,
        backgroundColor: 'transparent',
        tension: 0.4,
      },
      {
        label: 'Non-Coached Cohort Revenue',
        data: [
          100000,
          110000,
          120000,
          135000,
          140000,
          150000,
          165000,
          175000,
          190000,
          200000,
          215000,
          225000,
        ],
        borderColor: css.chartColor2,
        backgroundColor: 'transparent',
        tension: 0.4,
      },
    ],
  }

  // Sick Leave Data
  const sickLeaveData = {
    labels: months,
    datasets: [
      {
        label: 'Coached Group',
        data: [5, 4, 4, 3, 3, 2, 2, 2, 1, 1, 1, 1],
        backgroundColor: css.chartColor1,
      },
      {
        label: 'Non-Coached Group',
        data: [5, 5, 6, 5, 6, 5, 6, 5, 6, 5, 6, 5],
        backgroundColor: css.chartColor2,
      },
    ],
  }

  // Attrition Rate Data
  const attritionData = {
    labels: months,
    datasets: [
      {
        label: 'Coached Group Retention',
        data: [95, 96, 96, 97, 97, 98, 98, 98, 99, 99, 99, 99],
        borderColor: css.chartColor1,
        backgroundColor: 'transparent',
        tension: 0.4,
      },
      {
        label: 'Non-Coached Group Retention',
        data: [95, 94, 93, 92, 91, 90, 89, 88, 87, 86, 85, 84],
        borderColor: css.chartColor2,
        backgroundColor: 'transparent',
        tension: 0.4,
      },
    ],
  }

  // Sales Quota Data
  const quotaData = {
    labels: months,
    datasets: [
      {
        label: 'Coached Group',
        data: [85, 87, 90, 92, 95, 98, 100, 102, 105, 108, 110, 115],
        backgroundColor: css.chartColor1,
      },
      {
        label: 'Non-Coached Group',
        data: [85, 86, 85, 87, 86, 88, 87, 89, 88, 90, 89, 91],
        backgroundColor: css.chartColor2,
      },
    ],
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  }

  return (
    <div className="grid grid-cols-2 gap-5 mt-5">
      <div className="border border-gray-300 border-solid p-5 bg-white">
        <p className="font-semibold">Revenue Growth</p>
        <div className="flex justify-between items-center py-2">
          <FormControl style={{ width: 200 }}>
            <Select value={10}>
              <MenuItem value={10}>Last 12 months</MenuItem>
              <MenuItem value={20}>Last 6 months</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained">Report</Button>
        </div>
        <Line data={revenueData} options={chartOptions} />
      </div>

      <div className="border border-gray-300 border-solid p-5 bg-white">
        <p className="font-semibold">Average Sick Leave Days</p>
        <div className="flex justify-between items-center py-2">
          <FormControl style={{ width: 200 }}>
            <Select value={10}>
              <MenuItem value={10}>Last 12 months</MenuItem>
              <MenuItem value={20}>Last 6 months</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained">Report</Button>
        </div>
        <Bar data={sickLeaveData} options={chartOptions} />
      </div>

      <div className="border border-gray-300 border-solid p-5 bg-white">
        <p className="font-semibold">Retention Rate</p>
        <div className="flex justify-between items-center py-2">
          <FormControl style={{ width: 200 }}>
            <Select value={10}>
              <MenuItem value={10}>Last 12 months</MenuItem>
              <MenuItem value={20}>Last 6 months</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained">Report</Button>
        </div>
        <Line data={attritionData} options={chartOptions} />
      </div>

      <div className="border border-gray-300 border-solid p-5 bg-white">
        <p className="font-semibold">Sales Quota Attainment (%)</p>
        <div className="flex justify-between items-center py-2">
          <FormControl style={{ width: 200 }}>
            <Select value={10}>
              <MenuItem value={10}>Last 12 months</MenuItem>
              <MenuItem value={20}>Last 6 months</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained">Report</Button>
        </div>
        <Bar data={quotaData} options={chartOptions} />
      </div>
    </div>
  )
}
