import { RouteComponentProps, useParams, withRouter } from 'react-router-dom'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import * as yup from 'yup'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { createOrUpdateASurvey, getASurvey } from './helpers'
import { useEffect, useState } from 'react'
import { useConfirm } from 'material-ui-confirm'
import { useAlert } from 'react-alert'
import { fetchCurriculums } from '../HRCurriculumPage/HRCurriculumPage.helper'
import { createSurvey, editSurvey } from '../../../components/Breadcrumbs/BreadcrumbItems'

interface PropsI extends RouteComponentProps {}

interface FormFieldsI {
  id?: string
  name: string
  description: string
  curriculumId: string
  trigger: string
  isRequired: boolean
}

const validationSchema = yup.object({
  id: yup.string(),
  name: yup.string().label('Name').required(),
  curriculumId: yup.string().label('Curriculum').required(),
  trigger: yup.string().label('Trigger').required(),
  description: yup.string().label('Description').required(),
  isRequired: yup.boolean().label('Is Required'),
})

const HRSurveyEditPage: React.FC<PropsI> = (props) => {
  const { history } = props
  const { id } = useParams<{ id: string }>()
  const [data, setdData] = useState<any | null>(null)
  const [curriculums, setCurriculms] = useState<any[]>([])
  console.log('🚀 ~ curriculums:', curriculums)
  const alert = useAlert()

  useEffect(() => {
    if (id && id !== 'new') {
      getASurvey(id).then((res) => {
        setdData(res)
      })
    }
  }, [id])

  useEffect(() => {
    fetchCurriculums().then((res) => {
      setCurriculms(res.data)
    })
  }, [])

  const isNew = id === 'new'

  const submitButtonText = !isNew ? 'Update' : 'Create'

  const modifiedSurveyBreadcrumb = data?.name
    ? editSurvey.map((item) =>
        item.text === 'Edit ' ? { ...item, text: item.text + data.name } : item
      )
    : createSurvey

  const title = data?.name ? `Edit Survey` : 'Create Survey'

  return (
    <MainDashboardWrapper
      breadcrumbItems={modifiedSurveyBreadcrumb}
      title={title}
      page="corp-survey"
    >
      <Formik
        enableReinitialize
        initialValues={{
          name: data?.name || '',
          description: data?.description || '',
          trigger: data?.trigger || 'onSessionCompletion',
          curriculumId: data?.curriculumId || '',
          isRequired: data?.isRequired || false,
        }}
        onSubmit={(values: FormFieldsI, helpers) => {
          // console.log({ values })
          if (!isNew) {
            values.id = id
          }

          createOrUpdateASurvey(values)
            .then(() => {
              helpers.setSubmitting(false)
              alert.success(isNew ? 'Survey Created' : 'Survey updated')
            })
            .catch((err) => {
              console.log(err)
              alert.error('Something went wrong')
            })
            .finally(() => {
              history.push('/hr/survey')
            })
        }}
        validationSchema={validationSchema}
      >
        {(formikProps: FormikProps<FormFieldsI>) => {
          // console.log(formikProps.values)

          return (
            <Form className="flex flex-col gap-5">
              {/* <Field name="type">
                {(fieldProps: FieldProps) => {
                  const { field } = fieldProps
                  return (
                    <Select
                      variant="outlined"
                      value={field.value}
                      onChange={(event) => {
                        formikProps.setFieldValue(field.name, event.target.value)
                      }}
                    >
                      <MenuItem value="increase">Increase</MenuItem>
                      <MenuItem value="decrease">Decrease</MenuItem>
                    </Select>
                  )
                }}
              </Field> */}

              <Field
                component={TextField}
                required
                name="name"
                variant="outlined"
                label="Name"
                color="primary"
              />

              <Field
                component={TextField}
                required
                multiline
                rows={3}
                name="description"
                variant="outlined"
                label="Description"
                color="primary"
              />

              {/* <Field
                variant="outlined"
                component={Select}
                label="Curriculum"
                placeholder="Select a curriculum"
                name="curriculumId"
                // multiple={true}
                // inputProps={{name: 'tags', id: 'tags'}}
              >
                {curriculums.map((x) => {
                  return (
                    <MenuItem key={x.id} value={x.id}>
                      {x.name}
                    </MenuItem>
                  )
                })}
              </Field> */}

              <Field name="curriculumId">
                {({ field, form: { touched, errors }, meta }: FieldProps) => {
                  return (
                    <FormControl variant="outlined">
                      <InputLabel>Curriculum</InputLabel>
                      <Select
                        label="Curriculum"
                        value={field.value}
                        onChange={(e) => {
                          // console.log({ e })
                          // field.onChange(e.target.value)
                          formikProps.setFieldValue('curriculumId', e.target.value)
                        }}
                      >
                        {curriculums.map((x) => {
                          return (
                            <MenuItem key={x.id} value={x.id}>
                              {x.name}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  )
                }}
              </Field>

              <Field name="trigger">
                {({ field, form: { touched, errors }, meta }: FieldProps) => {
                  return (
                    <FormControl variant="outlined">
                      <InputLabel>Trigger</InputLabel>
                      <Select
                        label="Trigger"
                        value={field.value}
                        onChange={(e) => {
                          formikProps.setFieldValue('trigger', e.target.value)
                        }}
                      >
                        <MenuItem value={'onSessionCompletion'}>On Session Completion</MenuItem>
                      </Select>
                    </FormControl>
                  )
                }}
              </Field>

              <Field name="isRequired">
                {({ field }: FieldProps) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e) => {
                          formikProps.setFieldValue('isRequired', e.target.checked)
                        }}
                        color="primary"
                      />
                    }
                    label="Is Required"
                  />
                )}
              </Field>

              {/* <Field
                variant="outlined"
                component={Select}
                label="Trigger"
                placeholder="Select a curriculum"
                name="trigger"
              >
                <MenuItem value={'onProgramCompletion'}>On Program Completion</MenuItem>
              </Field> */}

              <div className="flex gap-5">
                <Button
                  className="w-fit"
                  color="primary"
                  variant="contained"
                  type="submit"
                  disableElevation
                  disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                >
                  {formikProps.isSubmitting ? <CircularProgress size={25} /> : submitButtonText}
                </Button>

                {!isNew && (
                  <Button
                    className="w-fit"
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      history.push(`/hr/survey/builder/${id}`)
                    }}
                  >
                    Go to Builder
                  </Button>
                )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </MainDashboardWrapper>
  )
}

export default withRouter(HRSurveyEditPage)
