import React, { useState, useEffect } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import css from './EmployeeTagPopup.module.scss'
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { Button, CircularProgress, MenuItem, Select as MUISelect } from '@material-ui/core'
import ReactSelect from 'react-select'
import * as yup from 'yup'
import SmallRoundCloseButton from '../SmallRoundCloseButton/SmallRoundCloseButton'
import { employeeTagManagement } from '../../containers/HR/HREmployeesPage/HREmployeesPage.helper'
import { useAlert } from 'react-alert'
import { getAllTags } from '../../containers/HR/HREmployeesPage/HREmployeesPage.helper'
import { useConfirm } from 'material-ui-confirm'
import EmployeeTagCreatePopup from '../../components/EmployeeTagCreatePopup/EmployeeTagCreatePopup'
import { PlusSquare } from 'react-feather'

interface FormFieldsI {
  tags: { value: string; label: string }[]
  type: string
}

interface PropsI {
  employeeList: any[]
  callFrom: string
  trigger: JSX.Element | ((isOpen: boolean) => JSX.Element)
  onSuccessCallback: () => void
}

const EmployeeTagPopup: React.FC<PropsI> = (props) => {
  const { trigger, onSuccessCallback } = props
  const [items, setItems] = useState<any[]>([]);
  const [tags, setTags] = useState<{ value: string; label: string }[]>([])
  const [loading, setLoading] = useState(false)
  const alert = useAlert()
  const confirm = useConfirm()

  // Fetch tags from API when popup opens
  const fetchTags = async () => {
    setLoading(true)
    try {
      const data = await getAllTags()
      if (data?.success && Array.isArray(data?.data)) {
        setTags(data.data.map((tag: any) => ({ value: tag.id, label: tag.name })))
      } else {
        alert.error('Failed to fetch tags: No tags found')
      }
    } catch (err) {
      console.error('Error:', err)
      alert.error('Error fetching tags: ' + err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTags()
  }, [])

  const onSubmit = (
    values: FormFieldsI,
    formikHelpers: FormikHelpers<FormFieldsI>,
    close: () => void
  ) => {
    confirm()
      .then(async () => {
        if (!items || items.length === 0) {
          alert.error('No employees selected')
          formikHelpers.setSubmitting(false)
          return
        }  
        const tagIds = values.tags.map((tag) => tag.value) 
        const ids = items.map((emp: any) => 
          props.callFrom  === 'employee-list' ? emp.id : emp
        );      
        if (ids) {
          await employeeTagManagement(ids, tagIds, values.type)
          .then((res) => {
            alert.success('Operation successfully!')
            onSuccessCallback()
            close()
          })
          .catch((error) => {
            alert.error('Error : ' + error)
            close()
          })
          .finally(() => {
            formikHelpers.setSubmitting(false)
          })
        }          
      })
      .catch(() => {
        formikHelpers.setSubmitting(false)
      })
  }

  const validationSchema = yup.object({
    tags: yup.array().min(1, 'At least one tag must be selected').required(),
    type: yup.string().required('Type is required'),
  })

  const initialValues: FormFieldsI = { tags: [], type: 'assign' }
  return (
    <Popup
      trigger={trigger}
      modal
      closeOnDocumentClick={false}
      //onOpen={() => setItems((props.employeeList === undefined || props.employeeList.length === 0 ? (window as any).selectedRows : props.employeeList)
      onOpen={() => setItems( props.callFrom  === 'employee-list' ? (window as any).selectedRows : props.employeeList
      )}
    >
      {(close: any) => (
        <div className={css.base}>
          <SmallRoundCloseButton onClick={close} className={css.close_button} />
          <div className={css.header}>
            <p className={css.title}>Assign Employee Tags</p>

            <EmployeeTagCreatePopup
              trigger={
                <div className={css.text_button}>
                  {' '}
                  <PlusSquare style={{ marginRight: '5px' }} /> Create Tags
                </div>
              }
              onSuccessCallback={() => fetchTags()}
            />
          </div>
          <p>
            {items?.length} employee{items?.length === 1 ? '' : 's'}{' '}
            {items?.length === 1 ? 'is' : 'are'} selected.
            
          </p>

          <Formik
            initialValues={initialValues}
            onSubmit={(...args) => onSubmit(...args, close)}
            validationSchema={validationSchema}
          >
            {(formikProps: FormikProps<FormFieldsI>) => (
              <Form className={css.form}>
                <Field name="type">
                  {(fieldProps: FieldProps) => {
                    const { field } = fieldProps
                    return (
                      <MUISelect
                        className={css.input_field}
                        variant="outlined"
                        value={field.value}
                        onChange={(event) => {
                          formikProps.setFieldValue(field.name, event.target.value)
                        }}
                      >
                        <MenuItem value="assign">Assign</MenuItem>
                        <MenuItem value="add">Add</MenuItem>
                        <MenuItem value="delete">Delete</MenuItem>
                      </MUISelect>
                    )
                  }}
                </Field>

                <Field name="tags">
                  {({ field }: any) => (
                    <ReactSelect
                      isMulti
                      options={tags} // Use the tags as options
                      value={formikProps.values.tags} // Bind to Formik's selected value
                      onChange={(selected) => {
                        // Handle multi-select and update Formik's field value
                        formikProps.setFieldValue(field.name, selected || [])
                      }}
                      placeholder="Select tags..."
                      isLoading={loading}
                    />
                  )}
                </Field>

                <Button
                  className={css.submit_button}
                  color="primary"
                  variant="contained"
                  type="submit"
                  disableElevation
                  disabled={formikProps.isSubmitting || !formikProps.isValid || !formikProps.dirty}
                >
                  {formikProps.isSubmitting ? <CircularProgress size={25} /> : 'Submit Tags'}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Popup>
  )
}

export default EmployeeTagPopup
