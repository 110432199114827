import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import css from './EmployeeTagCreatePopup.module.scss';
import { Field, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import SmallRoundCloseButton from '../SmallRoundCloseButton/SmallRoundCloseButton';
import { useAlert } from 'react-alert';
import { createTag } from '../../containers/HR/HREmployeesPage/HREmployeesPage.helper';

interface FormFieldsI {
  name: string;
  description: string;
}

interface PropsI {
  trigger: JSX.Element | ((isOpen: boolean) => JSX.Element);
  onSuccessCallback: () => void;
}

const CreateTagPopup: React.FC<PropsI> = ({ trigger, onSuccessCallback }) => {
  const [loading, setLoading] = useState(false);
  const alert = useAlert();

  const onSubmit = async (
    values: FormFieldsI,
    formikHelpers: FormikHelpers<FormFieldsI>,
    close: () => void
  ) => {
    setLoading(true);
    try {
      const response = await createTag(values.name, values.description);
      if (response?.success) {
        alert.success('Tag created successfully!');
        onSuccessCallback();
        close();
      } else {
        alert.error('Failed to create tag');
      }
    } catch (error) {
      console.error('Error creating tag:', error);
      alert.error('Error creating tag: ' + error);
    } finally {
      setLoading(false);
      formikHelpers.setSubmitting(false);
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required('Tag name is required'),
    description: yup.string().required('Description is required'),
  });

  const initialValues: FormFieldsI = { name: '', description: '' };

  return (
    <Popup trigger={trigger} modal closeOnDocumentClick={false}>
      {(close: any) => (
        <div className={css.base}>
          <SmallRoundCloseButton onClick={close} className={css.close_button} />
          <p className={css.title}>Create New Tag</p>

          <Formik
            initialValues={initialValues}
            onSubmit={(...args) => onSubmit(...args, close)}
            validationSchema={validationSchema}
          >
            {(formikProps: FormikProps<FormFieldsI>) => (
              <Form className={css.form}>
                <Field name="name">
                  {({ field, meta }: any) => (
                    <TextField
                      {...field}
                      label="Tag Name"
                      variant="outlined"
                      fullWidth
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      className={css.input_field}
                    />
                  )}
                </Field>

                <Field name="description">
                  {({ field, meta }: any) => (
                    <TextField
                      {...field}
                      label="Description"
                      variant="outlined"
                      fullWidth
                      error={meta.touched && Boolean(meta.error)}
                      helperText={meta.touched && meta.error}
                      className={css.input_field}
                    />
                  )}
                </Field>

                <Button
                  className={css.submit_button}
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={loading || !formikProps.isValid || !formikProps.dirty}
                >
                  {loading ? <CircularProgress size={25} /> : 'Create Tag'}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Popup>
  );
};

export default CreateTagPopup;
