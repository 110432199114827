import React, { Component, useCallback } from 'react'
import { connect } from 'react-redux'

import css from './HREmployeesPage.module.scss'
import { ThunkDispatch } from 'redux-thunk'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import BaseTable from '../../../components/BaseTable/BaseTable'
import { CellProps, Column } from 'react-table'
import { Avatar, Tooltip } from '@material-ui/core'
import { fetchEmployees } from '../../../ducks/employee.duck'
import { AvatarGroup } from '@material-ui/lab'
import moment from 'moment'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'
import { Plus, PlusSquare } from 'react-feather'
import TableActions from '../../../components/TableActions/TableActions'
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons'
import EmployeeBulkBudgetAllocationPopup from '../../../components/EmployeeBulkBudgetAllocationPopup/EmployeeBulkBudgetAllocationPopup'
import { HREmployeePageWithConfirm } from './HREmployeePageWithConfirm'
import { removeEmployeeFromCompany } from './HREmployeesPage.helper'
import EditEmployeePopup from '../../../components/EditEmployeePopup/EditEmployeePopup'
import EmployeeTagAssignPopup from '../../../components/EmployeeTagAssignPopup/EmployeeTagAssignPopup'


interface PropsI extends MapStatePropsI, MapDispatchPropsI, RouteComponentProps {
  confirm: (options: any) => Promise<void>
  alert: {
    success: (message: string | undefined) => void
    error: (message: string) => void
  }
}

interface StatesI {
  selectedRows: any[]
  isOpen: boolean,
  selectedEmployee: any
}

class HREmployeesPage extends Component<PropsI, StatesI> {
  constructor(props: PropsI) {
    super(props)

    this.state = {
      selectedRows: [],
      isOpen: false,
      selectedEmployee: {},
    }

    this.onSelectData = this.onSelectData.bind(this)
  }

  componentDidMount() {
    const { fetchEmployees } = this.props
    fetchEmployees()
  }

  onSelectData(values: any) {
    this.setState({
      selectedRows: values,
    })
  }

  removeEmployee = (employee: any) => {
    this.props
      .confirm({
        title: 'Are you sure?',
        confirmationText: 'Yes',
        cancellationText: 'No',
      })
      .then(() => {
        removeEmployeeFromCompany(employee?.original?.id)
          .then((res) => {
            this.props.alert.success(res?.message)
            this.props.fetchEmployees()
          })
          .catch((err) => {
            this.props.alert.error(err)
          })
      })
      .catch(() => {
        console.log("Cancelled!");
      });
  }

  handleEditEmployeeModal = (isEdited: boolean) => {
    this.setState({isOpen : !this.state.isOpen})
    if (isEdited) this.props.fetchEmployees();
  }

  editEmployee = (value: any) => {
    this.setState({selectedEmployee : value?.row?.original})
    this.handleEditEmployeeModal(false);
  }

  render() {
    const { employees, employeeFetchInProgress, history, fetchEmployees } = this.props

    const columns: Column[] = [
      {
        Header: 'Action',
        accessor: 'id',
        Cell: (tableProps: any) => (
          <TableActions
            isMenu={true}
            actions={[
              {
                icon: faEye,
                action: () => history.push(`/hr/employee/view/${tableProps.value}`),
                tooltip: 'View',
              },
              {
                icon: faEdit,
                action: () => this.editEmployee(tableProps),
                tooltip: 'Edit',
              },
              {
                icon: faTrash,
                action: () => this.removeEmployee(tableProps?.row),
                tooltip: 'Delete',
              },
            ]}
          />
        ),
        disableSortBy: true,
      },
      {
        Header: 'Employee',
        accessor: 'displayName',
        Cell: (props: any) => {
          if (!props.value) return ''

          const img = props.row.original.profilePic
          return (
            <span className={css.employee_cell}>
              <Avatar src={img || ''}>{props.value.charAt(0)}</Avatar>
              <span>{props.value}</span>
            </span>
          )
        },
      },
      {
        Header: 'Roles',
        accessor: 'UserCompanyModel.roles',
        Cell: (props) => {
          if (!props.value) return ''
          return (
            <AvatarGroup max={5}>
              {props.value.map((item: string, index: number) => (
                <Tooltip key={index} title={`${item.toUpperCase()}`}>
                  <Avatar sizes="10px" alt={`${item.charAt(0)}`}>
                    {item.toUpperCase().charAt(0)}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          )
        },
        disableSortBy: true,
      },
      {
        Header: 'Tags',
        accessor: 'tags',
        Cell: (props: any) => {
          const tags = props.row.original.tags || []

          if (tags.length === 0) return ''

          return (
            <AvatarGroup max={4}>
              {tags.map((tag: { id: string; name: string }) => (
                <Tooltip key={tag.id} title={tag.name.toUpperCase()}>
                  <Avatar sizes="5px" alt={`${tag.name.charAt(0)}`}>
                    {tag.name.charAt(0).toUpperCase()}
                  </Avatar>
                </Tooltip>
              ))}
            </AvatarGroup>
          )
        },
        disableSortBy: true,
      },
      // {
      //   Header: 'Email',
      //   accessor: 'email',
      // },
      {
        Header: 'Balance',
        accessor: '',
        Cell: (tableProps: CellProps<any>) => {
          const total =
            (tableProps.row.original.UserCompanyModel.currentRequiredBudget || 0) +
            (tableProps.row.original.UserCompanyModel.currentDiscretionaryBudget || 0)
          if (total !== 0) {
            return '$' + total?.toLocaleString()
          } else {
            return '$0'
          }
        },
      },

      {
        Header: 'Utilized',
        accessor: '',
        Cell: (tableProps: CellProps<any>) => {
          const total =
            (tableProps.row.original.UserCompanyModel.requiredUtilized || 0) +
            (tableProps.row.original.UserCompanyModel.discretionaryUtilized || 0)
          if (total !== 0) {
            return '$' + total?.toLocaleString()
          } else {
            return '$0'
          }
        },
      },

      {
        Header: 'Total',
        accessor: '',
        Cell: (tableProps: CellProps<any>) => {
          const utilized =
            (tableProps.row.original.UserCompanyModel.requiredUtilized || 0) +
            (tableProps.row.original.UserCompanyModel.discretionaryUtilized || 0)

          const balance =
            (tableProps.row.original.UserCompanyModel.currentRequiredBudget || 0) +
            (tableProps.row.original.UserCompanyModel.currentDiscretionaryBudget || 0)

          const total = utilized + balance

          if (total !== 0) {
            return '$' + total?.toLocaleString()
          } else {
            return '$0'
          }
        },
      },
    ]

    return (
      <MainDashboardWrapper title="Employees" page="corp-employees">
        <div className={css.actions}>
          <EmployeeTagAssignPopup
            trigger={<div className={css.text_button}>Assign Tags</div>}
            onSuccessCallback={() => fetchEmployees()}
            employeeList={[]}
            callFrom='employee-list'
            //employeeList={ [(window as any).selectedRows.map((row: { id: any }) => row.id)] } //this.state.selectedRows.map(row => row.id)
          />
          <EmployeeBulkBudgetAllocationPopup
            trigger={<div className={css.text_button}>Budget Allocation</div>}
            onSuccessCallback={() => fetchEmployees()}
          />
          <PrimaryButton name={'Add'} to="/hr/employee/add" icon={PlusSquare} />
        </div>
        <BaseTable
          columns={columns}
          data={employees}
          loading={employeeFetchInProgress}
          isSelectable
        />

        <EditEmployeePopup
          isOpen={this.state.isOpen}
          selectedEmployee={this.state.selectedEmployee}
          handleEditEmployeeModal={(isEdited)=>this.handleEditEmployeeModal(isEdited)}
        />
      </MainDashboardWrapper>
    )
  }
}

interface MapStatePropsI {
  employees: any[]
  employeeFetchInProgress: boolean
}

interface MapDispatchPropsI {
  fetchEmployees: () => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI): MapStatePropsI => {
  return {
    employees: state.employee.employees,
    employeeFetchInProgress: state.employee.employeeFetchInProgress,
  }
}

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppStateI, any, AppActionI>
): MapDispatchPropsI => {
  return {
    fetchEmployees: () => dispatch(fetchEmployees()),
  }
}

export default HREmployeePageWithConfirm(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(HREmployeesPage))
)
