import React, { BaseSyntheticEvent } from 'react'
// import css from './HRCurriculumEmployeeViewPage.module.scss'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import HRCurriculumEmployeeViewEnrollView from './HRCurriculumEmployeeViewEnrollView/HRCurriculumEmployeeViewEnrollView'
import HRCurriculumEmployeeViewBudgetView from './HRCurriculumEmployeeViewBudgetView/HRCurriculumEmployeeViewBudgetView'
import { Tab, Tabs } from '@material-ui/core'
import { budgetCurriculum } from '../../../components/Breadcrumbs/BreadcrumbItems'

const TABS = ['enrollments', 'budget']

function renderComponentByTab(tab: string) {
  switch (tab) {
    case TABS[0]:
      return <HRCurriculumEmployeeViewEnrollView />

    case TABS[1]:
      return <HRCurriculumEmployeeViewBudgetView />

    default:
      return <HRCurriculumEmployeeViewEnrollView />
  }
}

function getIndexByParams(tab: string) {
  switch (tab) {
    case TABS[0]:
      return 0

    case TABS[1]:
      return 1

    default:
      return 0
  }
}

interface PropsI extends RouteComponentProps<{ id: string; empId: string; tab: string }> {}

const HRCurriculumEmployeeViewPage: React.FC<PropsI> = (props) => {
  const { match } = props

  // return (
  //   <MainDashboardWrapper title="Employee Budget" page="corp-curriculum">
  //     {/*<div className={css.details}>*/}
  //     {/*  <div className={css.budget_card}>*/}
  //     {/*    <p>Curriculum Budget</p>*/}
  //     {/*    <p>${curriculum?.currentBudget.toLocaleString()}</p>*/}
  //     {/*  </div>*/}
  //
  //     {/*  <div className={css.budget_card}>*/}
  //     {/*    <p>Utilized</p>*/}
  //     {/*    <p>${curriculum?.utilized.toLocaleString()}</p>*/}
  //     {/*  </div>*/}
  //     {/*</div>*/}
  //     {/*<p>Employees</p>*/}
  //     {/*<div className={css.employees}>*/}
  //     {/*  {employees.map((e, i) => {*/}
  //     {/*    return (*/}
  //     {/*      <div key={i} className={css.user_name_base}>*/}
  //     {/*        <Avatar src={e.profilePic} />*/}
  //     {/*        <div className={css.user_name}>*/}
  //     {/*          <p>{e.displayName}</p>*/}
  //     {/*          <p>{e.location}</p>*/}
  //     {/*        </div>*/}
  //     {/*        <span className={css.user_action}>View</span>*/}
  //     {/*      </div>*/}
  //     {/*    )*/}
  //     {/*  })}*/}
  //     {/*</div>*/}
  //
  //     <BaseTable columns={columns} data={budgetRecords} />
  //   </MainDashboardWrapper>
  // )

  const selectedTabIndex = getIndexByParams(match.params.tab)

  const onTabChange = (event: BaseSyntheticEvent, tabIndex: number) => {
    const { history } = props
    switch (tabIndex) {
      case 0:
        history.push(`/hr/curriculum/details/${match.params.id}/${match.params.empId}/${TABS[0]}`)
        break

      case 1:
        history.push(`/hr/curriculum/details/${match.params.id}/${match.params.empId}/${TABS[1]}`)
        break
    }
  }

  const modifiedCurriculumBreadcrumb = budgetCurriculum.map((item) =>
      item.text === "Update Listing"
        ? { ...item, href: item.href + match.params.empId }
        : item
    )

  return (
    <MainDashboardWrapper breadcrumbItems={modifiedCurriculumBreadcrumb} title="Budget" page="corp-curriculum">
      <Tabs
        value={selectedTabIndex}
        indicatorColor="primary"
        textColor="primary"
        onChange={onTabChange}
      >
        <Tab label="Enrollment" />
        <Tab label="Budget" />
      </Tabs>
      {renderComponentByTab(match.params.tab)}

      {/*<BaseTable columns={columns} data={purchases} />*/}
    </MainDashboardWrapper>
  )
}

export default withRouter(HRCurriculumEmployeeViewPage)
