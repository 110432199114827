import { BreadcrumbItem } from './Types'

export const viewCurriculum: BreadcrumbItem[] = [
  { text: "Curriculum", href: "/hr/curriculum" },
  { text: "Details" },
];

export const createCurriculum: BreadcrumbItem[] = [
  { text: "Curriculum", href: "/hr/curriculum" },
  { text: "Add new" },
];

export const budgetCurriculum: BreadcrumbItem[] = [
  { text: "Curriculum", href: "/hr/curriculum" },
  { text: "Update Listing", href: "/hr/curriculum/edit/employees/" },
  { text: "Budget" },
];

export const editCurriculum: BreadcrumbItem[] = [
  { text: "Curriculum", href: "/hr/curriculum" },
  { text: "Edit " },
];

export const listingCurriculum: BreadcrumbItem[] = [
  { text: "Curriculum", href: "/hr/curriculum" },
  { text: "Update Listing" },
];

export const createSurvey: BreadcrumbItem[] = [
  { text: "Survey", href: "/hr/survey" },
  { text: "Create Survey" },
];

export const editSurvey: BreadcrumbItem[] = [
  { text: "Survey", href: "/hr/survey" },
  { text: "Edit " },
];

export const viewSurvey: BreadcrumbItem[] = [
  { text: "Survey", href: "/hr/survey" },
  { text: "Details" },
];

export const findCoaches: BreadcrumbItem[] = [
  { text: "Coaches", href: "/hr/coach" },
  { text: "Add Coaches" },
];

export const inviteEmployee: BreadcrumbItem[] = [
  { text: "Employees", href: "/hr/employee" },
  { text: "Invite employees" },
];

export const viewEmployee: BreadcrumbItem[] = [
  { text: "Employees", href: "/hr/employee" },
  { text: "" },
];

export const clientResource: BreadcrumbItem[] = [
  { text: "Settings", href: "/hr/settings" },
  { text: "Resources" },
];
