import React, { useState } from 'react'
import css from '../../containers/HR/HRCoachAddPage/HRCoachAddPage.module.scss'
import { Avatar, Tooltip } from '@material-ui/core'
import ReactHtmlParser from 'react-html-parser'
import { decode } from 'html-entities'
import LogoOnlyCircle from '../../assets/viveka-logo-only-circle.png'
import CoachViewOfferPopup from '../CoachViewOfferPopup/CoachViewOfferPopup'

interface PropsI {
  addCoachButtonHandle: (id: number, exist: boolean) =>  Promise<boolean>;
  item: any;
}

const Index: React.FC<PropsI> = ({ item, addCoachButtonHandle }) => {
  const [exist, setExist] = useState(item.exist);

  const handleClick = async (id: number, exist: boolean) => {
    setExist(!exist);
    const status = await addCoachButtonHandle(id, exist)
    if (!status) {
      setExist(exist);
    }
  };
  return (
    <div key={item.id} className={css.coach_card_base}>
      <div className='flex gap-2'>
        <Avatar src={item.profilePic} />
        <div className="space-y-1">
          <p className="font-bold">{item.displayName}</p>
          <p className="text-[12px] text-[#4f4f4f]">{item.location}</p>
        </div>
      </div>

      <div className='text-[#c4c4c4] mt-[10px]'>
        {ReactHtmlParser(decode(item.bio?.substring(0, 200)))}
      </div>

      <div className='flex gap-5 flex-wrap'>
        {item?.listings.map((l: any) => {
          return (
            <Tooltip key={l.id} title={l.name}>
              <div className={css.coach_listing}>
                {l?.images[0] ? (
                  <img alt={'listing'} src={l?.images[0]} />
                ) : (
                  <img
                    className={css.coach_listing_default_img}
                    alt={'default listing'}
                    src={LogoOnlyCircle}
                  />
                )}
              </div>
            </Tooltip>
          )
        })}
      </div>

      <div className='flex gap-5 mt-[10px]'>
        <p
          onClick={() => handleClick(item.id, exist)}
          className={`${exist ? "text-[#b70825] cursor-pointer" : "text-[#007373] cursor-pointer"}`}
        >
          {exist ? 'Remove' : 'Add'}
        </p>
        <CoachViewOfferPopup
          trigger={
            <p style={{ color: css.primaryColor3 }} className='cursor-pointer'>
              View Offers
            </p>
          }
          listings={item?.listings || []}
          authorName={item.displayName}
        />
      </div>
    </div>
  )
}

export default Index
