import { Button } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ThunkDispatch } from 'redux-thunk'
import MainDashboardWrapper from '../../../components/MainDashboardWrapper/MainDashboardWrapper'
import { fetchCurrentUser } from '../../../ducks/user.duck'
import { backendUrl } from '../../../env-config'
import { authAxios } from '../../../helpers/axios'
import AddLinkPopup from './AddLinkPopup'
import BaseTable from '../../../components/BaseTable/BaseTable'
import TableActions from '../../../components/TableActions/TableActions'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useConfirm } from 'material-ui-confirm'
import { clientResource } from '../../../components/Breadcrumbs/BreadcrumbItems'

interface PropsI extends MapDispatchPropsI {}

const HRResourcePage: React.FC<PropsI> = ({ fetchCurrentUserA }) => {
  const [items, setItems] = useState([])
  // console.log('🚀 ~ items:', items)

  const pageLoad = useCallback(() => {
    authAxios.get(`${backendUrl}/hr/settings/get-company-resources`).then((res) => {
      setItems(res.data)
    })
  }, [])

  useEffect(() => {
    pageLoad()
  }, [pageLoad])

  const confirm = useConfirm()

  const columns: any[] = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Url',
      accessor: 'url',
    },
    {
      Header: 'Actions',
      disableSortBy: true,
      Cell: ({ row }: any) => {
        return (
          <TableActions
            actions={[
              {
                icon: faTrash,
                action: () => {
                  confirm({
                    title: 'Are you sure?',
                  }).then(() => {
                    authAxios
                      .post(`${backendUrl}/hr/settings/delete-company-resources`, {
                        id: row?.original?.id,
                      })
                      .then((res) => {
                        pageLoad()
                      })
                  })
                },
              },
            ]}
          />
        )
      },
    },
  ]

  return (
    <MainDashboardWrapper breadcrumbItems={clientResource} title="Resources" page="corp-settings">
      <AddLinkPopup
        cb={pageLoad}
        trigger={
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disableElevation
            className="w-fit"
          >
            Add a link
          </Button>
        }
      />

      <BaseTable columns={columns} data={items} />
    </MainDashboardWrapper>
  )
}

interface MapDispatchPropsI {
  fetchCurrentUserA: () => Promise<ApiResponseI>
}

const mapStateToProps = (state: AppStateI) => {
  return {
    currentUser: state.user.currentUser,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AppActionI>) => {
  return {
    fetchCurrentUserA: () => dispatch(fetchCurrentUser()),
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HRResourcePage))
